@import "../../../variables";
.avatar-container {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    .avatar-image-wrapper {
        $avatar-box: 180px;
        margin-bottom: 15px;
        padding: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: $avatar-box;
        height: $avatar-box;
        border-radius: 50%;
        background-color: $avatar-bg-red;
        transition: all 0.1s linear;
        cursor: pointer;
        z-index: 1;
        
        img {
            position: absolute;
            width: 75%;
        }
        &:hover {
            background-color: rgba($color: $avatar-bg-red, $alpha: 0.12);
        }
        .initials-view {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
        }
    }
    span {
        font-size: 20px;
        z-index: 1;
    }
}