@import "../../../variables";

.DatePicker {
    &:disabled {
        background-color: lightgray;
    }
}

.profile-container {
    background-color: $search-bg;
    &.disabled {
        opacity: 0.7 !important;
        pointer-events: none !important;
        .image-wrapper {
            pointer-events: none !important;
        }
    }
    .profile-info-container {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        .profile-info {
            margin-top: auto;
            // h3 {
            //     margin: 0;
            //     width: 100%;
            //     font-size: 30px;
            //     font-weight: bold;
            //     color: $font-color;
            // }
            // p {
            //     text-align: left;
            //     color: #a4a5aa;
            // }
            span {
                font-size: 14px;
                font-weight: 600;
                color: #464343;
                text-transform: uppercase;
            }
            .buttons-wpapper {
                margin-bottom: 5px;
                display: flex;
                width: 100%;
                .image-wrapper {
                    $icon-size: 45px;
                    margin-right: 20px;
                    flex: 0 0 auto;
                    width: $icon-size;
                    height: $icon-size;
                    overflow: hidden;
                    border-radius: $icon-size;
                    background-color: #fae6e8;
                    transition: all 0.1s linear;
                    cursor: pointer;
                    img {
                        padding: 12px;
                    }
                    &:hover {
                        background-color: #f1cace;
                    }
                }
            }
            @media (max-width: 575.98px) {
                text-align: center;
                margin: auto;
            }
        }
    }
    .event-info-container {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        .event-info {
            h3 {
                margin: 3px 0;
                width: 100%;
                font-size: 24px;
                font-weight: bold;
                color: $font-color;
            }
            p {
                margin-bottom: 8px;
                text-align: left;
                color: #a4a5aa;
                font-size: 18px;
            }
            span {
                font-size: 14px;
                font-weight: 600;
                color: #464343;
                text-transform: uppercase;
            }
        }
    }
    .search-container {
        .dropdown-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100%;
            .dropdown {
                max-width: 270px;
            }
            .button {
                margin: 0;
                flex-direction: row-reverse;
                background-color: #464343;
                img {
                    margin: 0 10px 0 0;
                }
                &:hover {
                    background-color: #333;
                }
            }
        }
    }
}

.profile-info label {
    &.disabled {
        opacity: 0.5 !important;
        pointer-events: none !important;
    }
}

.edit-profile {
    &.disabled label {
        opacity: 0.7 !important;
        pointer-events: none !important;
    }
    &.disabled input {
        opacity: 0.7 !important;
        pointer-events: none !important;
    }
    &.disabled select {
        opacity: 0.7 !important;
        pointer-events: none !important;
    }
    &.disabled button {
        opacity: 0.7 !important;
        pointer-events: none !important;
    }
    input {
        padding: $input-p;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: #fff;
        color: #3a4256;
        border-color: #b3bed1;
        border-radius: 10px;
        background-size: 15px;
        background-repeat: no-repeat;
        background-position-x: calc(100% - 12px);
        background-position-y: 50%;
        appearance: none;
        transition: all 0.2s;
        &:hover {
            box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 0.5);
        }
    }
}

.profile-info {
    label {
        margin-bottom: 0px;
    }
    input {
        padding: 15px;
    }
}